import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularIndeterminate() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifycontent: "center",
        alignitems: "center",
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
}
