/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
//import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute'

//import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute'

//const SignIn = lazy(() => import('../pages/SignIn/SignIn'))

//const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
//const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
const Upload = lazy(() => import('../pages/Upload/Upload'))
const RunResults = lazy(() => import('../pages/Run'))
//const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))

const routes = [
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/upload',
    exact: true,
    element: (
      <Upload is3D={false} id="upload" />
    ),
  },
  {
    path: '/upload3d',
    exact: true,
    element: (
      <Upload is3D={true} id="upload3d" />
    ),
  },
  {
    path: '/run/:job_id',
    element: (
      <RunResults />
    )
  },
]

export default routes
